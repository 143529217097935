<template>
    <div>
        <PageFilter 
            model="users.ProfileModel"
            :key="widget.page_name"
            ref="filter"
            :page_name="widget.page_name" />
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter/PageWidget.vue'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        },
        closeSettingDrawer: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        PageFilter
    },
    methods: {
        saveConfig() {
            this.$nextTick(() => {
                this.$refs.filter.setFilter()
                this.closeSettingDrawer()
            })
        },
        resetConfig() {
            this.$nextTick(() => {
                this.$refs.filter.removeFilters()
                this.closeSettingDrawer()
            })
        }
    }
}
</script>